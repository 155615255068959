import React from "react"
import Title from "./Title"
import Blog from "./Blog"
import { Link } from "gatsby"
export const Blogs = ({ blogs, title, showLink, home }) => {
  return (
    <section className={`section ${home ? "bg-grey" : ""}`}>
      <Title title={title} />
      <div className="section-center blogs-center">
        {blogs.map(item => {
          return <Blog key={item.id} {...item} />
        })}
      </div>
      {showLink && (
        <Link to="/blog" className="btn center-btn">
          blog
        </Link>
      )}
    </section>
  )
}
export default Blogs
