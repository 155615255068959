import React from "react"
import { RiCommunityLine } from "react-icons/ri"
import { AiOutlineExperiment } from "react-icons/ai"
import { IoIosSchool } from "react-icons/io"

export default [
  {
    id: 1,
    icon: <AiOutlineExperiment className="service-icon" />,
    title: "Research",
    text: `software ecosystems, diversity and inclusion, privacy, requirements engineering, communication and coordination, global software development`,
    url: "/research/",
  },
  {
    id: 2,
    icon: <IoIosSchool className="service-icon" />,
    title: "Teaching",
    text: `experiential learning, in-context, industry-relevant`,
    url: "/teaching/",
  },
  {
    id: 3,
    icon: <RiCommunityLine className="service-icon" />,
    title: "Service",
    text: `international research community, mentoring`,
    url: "/service/",
  },
]
