import React, { useState } from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/socialLinks"

const query = graphql`
  {
    datoCmsHome {
      longDescription
      shortDescription
      image {
        fixed(width: 800, height: 500) {
          # Set desired width and height here
          ...GatsbyDatoCmsFixed
        }
      }
    }
  }
`

const Hero = () => {
  const data = useStaticQuery(query)
  const { datoCmsHome } = data

  const { shortDescription, image } = datoCmsHome

  // State to manage tooltip visibility
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <header className="hero">
      <div
        className="section-center hero-center"
        style={{ display: "flex", gap: "20px" }}
      >
        <article className="hero-info" style={{ marginRight: "20px" }}>
          <div>
            <div className="underline"></div>
            <h1>{shortDescription}</h1>
            <h3 style={{ textTransform: "none" }}>
              Professor of Software Engineering <br /> ECS-CAPI Chair in
              Inclusive Science, Technology and Engineering
            </h3>
            <h3 style={{ textTransform: "none" }}>
              University of Victoria, BC, Canada
            </h3>
            <SocialLinks />
          </div>
        </article>
        <div className="image-container" style={{ position: "relative" }}>
          {/* Banner Section */}
          <a
            href="https://youtu.be/Fls4woOKQik"
            target="_blank"
            rel="noopener noreferrer"
            className="banner"
            style={{
              display: "block",
              backgroundColor: "#007ACC",
              color: "#fff",
              textAlign: "center",
              padding: "10px",
              fontSize: "16px",
              textDecoration: "none",
              marginBottom: "10px",
              borderRadius: "4px",
            }}
          >
            Watch my recent TEDxVictoria talk: Empathy as Technology
          </a>

          {/* Image Section */}
          <Image
            fixed={image.fixed}
            className="hero-img"
            style={{ marginLeft: "20px" }}
            alt="TEDx Victoria Image"
          />
        </div>
      </div>
    </header>
  )
}

export default Hero
