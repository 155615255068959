import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Projects from "../components/Projects"
import Blogs from "../components/Blogs"
import SEO from "../components/SEO"
export default ({ data }) => {
  const {
    allDatoCmsProject: { nodes: projects },
    allDatoCmsBlog: { nodes: blogs },
  } = data
  return (
    <Layout>
      <SEO title="Home" description="Daniela's home page" />
      <Hero />
      <Services />
      <Projects projects={projects} title="featured projects" showLink home />
      <Blogs blogs={blogs} title="latest blogs" showLink home={true} />
    </Layout>
  )
}

export const query = graphql`
  {
    allDatoCmsProject(filter: { feature: { eq: true } }) {
      nodes {
        id
        description
        url
        slug
        image {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        title
      }
    }
    allDatoCmsBlog(sort: { order: DESC, fields: date }, limit: 3) {
      nodes {
        slug
        title
        image {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        content
        category {
          title
        }
        date(formatString: "MMMM Do, YYYY")
        description
        id
      }
    }
  }
`
