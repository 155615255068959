import React from "react"
import Title from "./Title"
import services from "../constants/services"
import { Link } from "gatsby"
const Services = () => {
  return (
    <section className="section bg-grey">
      <Title title="" />
      <div className="section-center services-center">
        {services.map(service => {
          const { id, title, text, url } = service

          return (
            <Link to={url} key={id}>
              <article className="service">
                <h3 className="service-title">{title}</h3>
                <div className="underline"></div>
                <p>{text}</p>
              </article>
            </Link>
          )
        })}
      </div>
    </section>
  )
}

export default Services
