import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import { FaShareSquare } from "react-icons/fa"
import ReactMarkdown from "react-markdown"

const Project = ({ description, title, url, image, index, home }) => {
  return (
    <article className="project">
      {image && <Image fluid={image.fluid} className="project-img" />}
      <div className="project-info">
        <span className="project-number">0{index + 1}.</span>
        <h3>{title}</h3>
        <p className="project-desc">
          <p style={{ whiteSpace: "pre-wrap" }}>
            {home && description.length > 200 ? (
              <Fragment>{description.slice(0, 200)}..... </Fragment>
            ) : (
              description
            )}
          </p>
        </p>
        <div className="project-links">
          {url && (
            <a href={url}>
              <FaShareSquare className="project-icon" />
            </a>
          )}
        </div>
      </div>
    </article>
  )
}

Project.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
}

export default Project
