import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import { Link } from "gatsby"
const Blog = ({ id, title, image, date, category, slug, description }) => {
  return (
    <Link to={`/blogs/${slug}`} key={id}>
      <article>
        {image && <Image fluid={image.fluid} className="blog-img" />}
        <div className="blog-card">
          <p>{title}</p>
          <div className="blog-footer">
            <p>{category && category.title}</p>
            <p>{date}</p>
          </div>
        </div>
      </article>
    </Link>
  )
}

Blog.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  category: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
}

export default Blog
