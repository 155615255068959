import React from "react"
import Title from "./Title"
import PropTypes from "prop-types"
import Project from "./Project"
import { Link } from "gatsby"
const Projects = ({ projects, title, showLink, home }) => {
  return (
    <section className="section projects">
      <Title title={title} />
      <div className="section-center projects-center">
        {projects.map((project, index) => {
          return (
            <Link to={`/projects/${project.slug}`}>
              <Project key={project.id} index={index} {...project} home />
            </Link>
          )
        })}
      </div>
      {showLink && (
        <Link to="/projects" className="btn center-btn">
          Projects
        </Link>
      )}
    </section>
  )
}

Projects.propTypes = {
  projects: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  showLink: PropTypes.bool.isRequired,
}

export default Projects
